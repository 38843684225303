export const LOGIN_URL = "loginSubmit";
export const REGISTER_OTP_URL = "signupPost";
export const REGISTER_URL = "saveRegisterUser";
export const RESEND_OTP = "signupResendOtp"
export const CHANGE_MAIL = "ChangeMailView";
export const CHANGE_PASSWORD = "user/changePassword";
export const FORGOT_PASSWORD = "forgotpasswordotpPost";
export const CHANGE_MAIL_ID = "changeEmailId";
export const GET_USER_DETAILS = "user/getUserDetails";
export const UPDATE_PROFILE = "user/updateProfile/";
export const SERVICE_LISTALL = "api/services/listAll";
export const MDA_LISTALL = "api/mda/listAll";
export const CATEGORY_LISTALL = "api/category/listAll";
export const CMS_LISTALL = "api/cms/staticBlocks/listAll";
export const GET_BANNER_LIST = "api/cms/banner/getList";
export const GET_RESETPASSWORD_EMAIL = "getDetailsBasedCode";
export const UPDATE_RESETPASSWORD ="user/updatePassword";
export const APP_LOGOUT = "logout";
export const EXCHANGE_RATE = "api/external/exchangeRate/listAll"
export const TTC_NUMBER_VALIATOR = "api/external/tccServices/validateTccService"
export const VEHICLE_MAKE = "api/external/vehicleInfo/getVehicleMake"
export const VEHICLE_MODEL = "api/external/vehicleInfo/getVehicleModel?vehicleMakeCode="
export const BODY_TYPE = "api/external/vehicleInfo/getVehicleBody"
export const VEHICLE_SAVE = "api/external/vehicleInfo/save"
export const GET_TCC_TYPE = "api/external/tccServices/getTccType"
export const TCC_SERVICE_SAVE = "api/external/tccServices/save"
export const NOTIFY_COUNT = "common/loadPopupNotifyCount"
export const NOTIFY_LIST = "admin/notificationReport/listAll"
export const NOTIFY_DETAIL = "admin/notificationReport/notification_detail/"
export const CONTACT_SEND = "contact-us/send"
export const TCC_LIST_ALL = "api/external/tccServices/listAll"
export const STATIC_HEADER = "staticBlockList?page_title=Header"
export const STATIC_FOOTER = "staticBlockList?page_title=Footer"
export const STATIC_HELP_CENTER = "staticBlockList?page_title=Help_Center"
export const STATIC_PAGE = "api/cms/staticPages/listAll"
export const HEALTH_FACILITY_DISTRICT = "api/external/healthfacility/getDistrict"
export const HEALTH_FACILITY_TYPE = "api/external/healthfacility/getFacilityType"
export const FREQUENT_SERVICE = "api/services/frequentService"
export const Activity_LOG = "admin/service-report/listAll"
export const HEALTH_FACILITY_LIST = "api/external/healthfacility/search"
export const NIN_NO_VALIDATE = "nrb/getNrbId?IDNumber="
export const CHECK_TOKEN = "checkToken"
export const APPLICATION_COUNT = "api/applicationCount"
export const FAVORITE_SERVICES = "api/favoriteServices"
export const LATEST_APPLICATIONS = "api/allApplications"
export const APPLICATION_ACTIVITIES = "api/applicationActivities"

export const TRANSACTION_PAY = "api/transaction/pay" // api/external/transaction/pay // api/transaction/pay
export const TRANSACTION_UPDATE = "api/transaction/update" //api/external/transaction/update // api/transaction/update
export const UBS_MDA_LIST = "api/mda/listAll" // api/external/mda/listAll // api/mda/listAll
export const MDA_SERVICE_LIST = "api/mda/service-list?id=" //api/external/mda/service-list // api/mda/service-list?id=
export const ALL_SERVICES_LIST = "api/allServices/list?search=" // api/external/allServices/list // api/allServices/list?search=
export const SERVICES_DETAIL = "api/service/" // api/external/service //api/service/