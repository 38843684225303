import { Container } from "@mui/material";
import {useRef,React} from "react";
import "./PaymentSuccessFailed.css";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import PDFGenerator from "./PDFGenerator";

const PaymentSuccess = ({ data, handleclose, paymenttype }) => {
  console.log(data);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pdfRef = useRef();

  const handleDownload = () => {
    pdfRef.current.generatePdf();
  };
  const handlePrint = () => {
    // let printContents = document.getElementById('printablediv').innerHTML;
    // let originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
  };

  const handleGoHome = () => {
    navigate("/");
  };

  /* const getCurrentDateTime = () => {
    const now = new Date();
    const padZero = (num) => (num < 10 ? '0' + num : num);
    const day = padZero(now.getDate());
    const month = padZero(now.getMonth() + 1); // Months are zero-indexed in JS
    const year = now.getFullYear();
    let hours = now.getHours();
    const minutes = padZero(now.getMinutes());
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = padZero(hours); 

    return `${day}/${month}/${year}`;
  }; */

  const formatDateTime = (value) => {
    var date = new Date(value);
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1;
    let dd = date.getDate();
    let hr = date.getHours();
    let min = date.getMinutes();
    var ampm = "AM";

    if (hr >= 12) {
      ampm = "PM";
    }

    if (hr > 12) {
      hr = hr - 12;
    }

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (min < 10) min = "0" + min;

    const formattedToday =
      dd + "-" + mm + "-" + yyyy;
    // dd + "-" + mm + "-" + yyyy + " " + hr + ":" + min + " " + ampm;
    return formattedToday;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB').format(date); // Formats date to dd/mm/yyyy
  };

  return (
    <>
      <div className="service-header">
        <Container>
          <div className="service-header-inner"></div>
        </Container>
      </div>
      <Container maxWidth="md" id="printablediv">
        <div className="service-page-detail success-section pay-success">
          <div className="content-wrapper payment-page">
            <div className="pay-head">
              <h3>{paymenttype.id === "1" ? t("payment receipt") : t("payment slip")}</h3>
              
              <p className="payAmt"> 
                <span>{paymenttype.id === "2" ? t("For the Bank Payment with the Amount") : ('')} </span> 
                <span>{paymenttype.id === "4" ? t("For the offline Payment with the Amount") : ('')} </span> 
                  {data ? (paymenttype.id === "1" ? data.amount : data.amount) : ''}</p>
             
            </div>
            <div className="pay-icon">
              {/* <img src={SuccessTick} alt="Success" className="success-gif" /> */}
              <CheckCircleIcon style={{ fontSize: 45 }}/>
              <div className="pay-border">
                <p className="pay-ref-no-txt">{t("Pay Ref No full text")}</p>
                <p className="pay-ref-no-txt pay-no">{data ? (paymenttype.id === "1" ? data.data.prn : data.prn_no) : ''}</p>
              </div>
              <button onClick={handleDownload} className="print-button">{paymenttype.id === "1" ? t("print receipt") : t("print slip")}</button>
            
            <div style={{ justifyContent: 'center', marginLeft: '20px', marginRight: '20px' }}>
              <p className="pay-ref-no-note red-tx">{paymenttype.id === "1" ? '' : (t("expire alert") + (data && data.expired_date !== "" && data.expired_date !== null ? formatDate(data.expired_date) : '') + t("expire alert1"))}</p>
            </div>
            <div className="payment-details">
              <table className="pay-table bank-table">
                <thead>
                  <th>{t("service detail")}</th>
                  <th>{t("citizen detail")}</th>
                </thead>
                <tbody>
                  <tr>
                    <td> {data ? (<> <span>{(paymenttype.id === "1" ? data.data.service_name : data.service_name)}</span>  <br /> <span> {(paymenttype.id === "1" ? data.amount : data.amount)}</span> </> ) : ''} </td>
                    <td> {data ? (<> <span> {data.citizen_username} </span> <br /> <span>NIN: {data.citizen_payerId}</span> <br /> <span>{data.citizen_email}</span> </>) : ''} </td>
                  </tr>                  
                </tbody>
              </table>              
            </div>
            </div>
            {paymenttype.id === "2"  && <div className="payment-details">
              <table className="pay-table sub-pay">
                <thead>
                  <th>{t("bank detail")}</th>
                  <th></th>
                </thead>
                <tbody>
                  <tr>                    
                    <td className="wd-xs">{data ? <>{t('bank name')}</> : ''}</td>
                    <td className="wd-sm">{data ? <>{ data.bank_name || ''}</> : ''}</td>
                  </tr>
                  <tr>
                    <td className="wd-xs">{data ? <>{t('account no')}</> : ''}</td>
                    <td className="wd-sm">{data ? <>{data.account_number || ''}</> : ''}</td>
                  </tr>
                  <tr>
                    <td className="wd-xs">{data ? <>{t('branch')}</> : ''}</td>
                    <td className="wd-sm">{data ? <>{data.branch_name || ''}</> : ''}</td>
                  </tr>
                </tbody>
              </table>
            </div>}
            <div style={{ justifyContent: 'center' }}>
              <p className="pay-ref-no-note gray-txt">{paymenttype.id === "1" ? t("receipt alert") : paymenttype.id === "2" ? t("bank alert") : t("offline alert")}</p>
            </div>
            <div className="go-to-home">
              <button onClick={handleGoHome} className="go-home">{t("Go Home")}</button>
            </div>
          </div>
        </div>
        <PDFGenerator ref={pdfRef} data={data} paymenttype={paymenttype}/>
      </Container>
    </>
  );
};

export default PaymentSuccess;