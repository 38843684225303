import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import Menu from './components/Admin/Menu/Menu';
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Login from "./components/Account/Login/Login";
import Register from "./components/Account/Register/Register";
import NoPage from "./components/NoAccess/NoAccess";
import PageNotFound from "./components/NoAccess/PageNotFound";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import AppContext from "./utils/context";
import BusinessService from "./components/BusinessService/BusinessService";
import ForgotPassword from './components/Account/Password/ForgotPassword';
import PersonalInformation from './components/Admin/PersonalInformation/ProfileInformation';
import ChangeMail from './components/Account/Password/ChangeMail';
import ChangePassword from './components/Account/Password/ChangePassword';
import PageLoader from "./components/Loader/PageLoader";
import ResetPassword from "./components/Account/Password/Resetpassword";
import CmsPage from "./components/Footer/Pages/CmsPage";
import Travelform from "./components/Home/Form/Travelform";
import ServicesList from "./components/Services/ServicesList";
import ViewAllServiceSchemeMda from "./components/SchemeListAll/SchemeList";
import ServicePageLayout from "./components/SeviceScreen/ServicePageLayout";
import TccAdd from "./components/SeviceScreen/TccAdd";
import BusinessMDAService from "./components/BusinessSchemeMDA/BusinessSchemeMDA";
import NotificationList from "./components/Admin/Notification/NotificationList";
import SearchPage from "./components/Search/SearchPage";
import StaticPage from "./components/Footer/Pages/StaticPage";
import MyActivity from "./components/Admin/Activities/MyActivity";
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import LatestApplication from "./components/Admin/LatestApplication/LatestApplication";
import Makepayment from "./components/Makepayment/Makepayment";
import AllServices from "./components/AllServices/AllServices";
import AllServicesDetail from "./components/AllServices/AllServicesDetail";

function App() {
  const [apiToken, setApiToken] = useState('');
  const [userDetail, setUserDetail] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const setToken = (token) => {
    localStorage.setItem('token', JSON.stringify(token));
    
  }
  const getToken = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    setApiToken(token)
  }

  const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  }
  const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUserDetail(user)
  }

  useEffect(() => {
    getToken();
    getUser();
  }, []);

  return (
    <HashRouter >
      <AppContext>
        <div className={`App ${apiToken ? 'loggedIn' :'loggedOut'} ${!showHeader && !showFooter ? 'NoAccessPage' :''} `}>
          {showHeader && 
            <Header apiToken={apiToken} userDetail={userDetail} className=""/>
          }
          <Routes>
          <Route path="/" element={<Menu />}>
            <Route index element={<Home />}></Route>
            <Route path="login" element={<Login setApiToken={setApiToken} setToken={setToken} setUser={setUser}/>}></Route>
            <Route path="register" element={<Register />}></Route>
            <Route path=":services/services/:id" element={<BusinessService />}></Route>
            <Route path=":mda/mda/:id" element={<BusinessMDAService />}></Route>
            <Route path="services-list" element={<ServicesList />}></Route>
            <Route path="scheme-list" element={<ViewAllServiceSchemeMda />}></Route>
            <Route path="detail/:services/:services/:name" element={<ServicePageLayout />}></Route>
            <Route path="detail/:services/:service/:name/tcc-add" element={<TccAdd />}></Route>
            <Route path="Travelform" element={<Travelform/>}></Route>
            <Route path="forgot-password" element={<ForgotPassword />}></Route>
            <Route path="user/resetPassword/:code" element={<ResetPassword />}></Route>
            <Route path="my-account/profile-information" element={<PersonalInformation />}></Route>
            <Route path="my-account/change-mail" element={<ChangeMail />}></Route>
            <Route path="my-account/change-password" element={<ChangePassword />}></Route>
            <Route path="my-account/my-notification" element={<NotificationList />}></Route>            
            <Route path="my-account/my-activity" element={<MyActivity />}></Route>
            <Route path="my-account/dashboard" element={<Dashboard />}></Route>
            <Route path="my-account/latest-application" element={<LatestApplication />}></Route>
            <Route path="cms/:pagename" element={<CmsPage />}></Route>
            <Route path="cms-page/:pageurl" element={<StaticPage />}></Route>
            <Route path="search" element={<SearchPage/>}></Route>
            <Route path="no-access" element={<NoPage setShowHeader={setShowHeader} setShowFooter={setShowFooter} />}></Route>
            <Route path="*" element={<PageNotFound setShowHeader={setShowHeader} setShowFooter={setShowFooter} />}></Route>
            <Route path="make-payment" element={<Makepayment />}></Route>
            <Route path="all-services" element={<AllServices />}></Route>
            <Route path="all-services-detail/:servicename" element={<AllServicesDetail />}></Route>
          </Route>
        
          </Routes>
          <PageLoader />
          {showFooter && 
            <Footer/>
          }
        </div>
      </AppContext>
    </HashRouter>
  );
}

export default App;